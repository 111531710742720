  <!-- Bandit Visual Games 2021 -->
  <div class="scaleable-wrapper" id="scaleable-wrapper">
    <div class="cover-image" id="cover-image">
      <section id="top">
        <div id='hero' aria-label="Foul Play Banner Featuring Winter and Main Character">
          <div class='layer-bg layer' data-depth='0.90' data-type='parallax'></div>
          <div class='layer-moon layer' data-depth='0.90' data-type='parallax'></div>
          <div class='layer-1 layer' data-depth='0.50' data-type='parallax'></div>
          <div class='layer-2 layer' data-depth='0.40' data-type='parallax'></div>
          <div class='layer-3-w layer' data-depth='0.80' data-type='parallax'></div>
          <div class='layer-3 layer' data-depth='0.80' data-type='parallax'></div>
          <div class='layer-overlay layer' data-depth='0.25' data-type='parallax'></div>
          <div class='layer-4 layer' data-depth='1.00' data-type='parallax'></div>
        </div>
        <div id='hero-mobile' aria-label="Foul Play Banner Featuring Winter and Main Character"></div>
      </section>
    </div>
  </div>
  <div id='content'>
    <div class='container'>
      <section class='first-section page-section' id="game">
        <div class="pimg1" id="pimg" aria-label="Foul Play"></div>
        <div class="pimg1-m" id="pimg1-m"></div>
        <div class='row'>
          <div>
            <h2 class="title">"Justice is subjective."</h2>
            <br>
            <p>You play as a straight-laced investigator who has always been bound to the rules and you successfully live
              an unfulfilling, stable, mundane life. Although you're apt at solving crimes from behind a desk, you have an
              impressive track record of fumbling when you come across criminals of a more... <i>feminine</i> nature.
            </p>
            <br>
            <p>
              Just as you're starting to question if you're really as vanilla, average, and...straight as you thought, you
              are given a last chance at redemption: catch a mercurial, high-profile assassin who has been toying with the
              secret service for her own personal thrills. When your paths cross, you'll have to decide how pure your
              conscience really is. After all, justice is subjective.
            </p>
            <br>
            <p>
              You find yourself drawn to the assassin. She is unfiltered, bold and embodies the kind of power that you
              begrudgingly admire in another woman. She's also not too hard on the eyes. In return, she seems particularly
              fascinated with pushing you to the limits in order to unveil a darker side of you and unleash your deepest
              desires.
            </p>
            <p>
              Featured <a href="https://gameskeys.net/top-steam-games-to-tryout-in-september-2021/" target="_blank" alt="Link Game Keys">here</a> on 
              <a href="https://gameskeys.net/top-steam-games-to-tryout-in-september-2021/" target="_blank"><img class="img-p" src="../../assets/images/gamekeys.png" alt="Button Game Keys"></a>
            </p>
          </div>
        </div>
        </section>
      <div class="socials">
          <a href="https://store.steampowered.com/app/1600930/Foul_Play__Yuri_Visual_Novel/" target="_blank"><img
            src="../../assets/images/steam.png" alt="Button Select Steam"></a>
          <a href="http://kck.st/31eXBsr" target="_blank"><img
            src="../../assets/images/funded_KS_banner.png" alt="Button Select Funded By Kickstarter"></a>
      </div>
      <div class="socials">
        <iframe frameborder="0" src="https://itch.io/embed/717342?border_width=0&amp;bg_color=080808&amp;fg_color=ffffff&amp;link_color=9e5afa" width="550" height="165"><a href="https://banditvisualgames.itch.io/foul-play">Foul Play by Bandit Visual Games</a></iframe>
      </div>

      <section class="page-section" id='characters'>
        <div class="pimg2" id="pimg2">
          <div class="ptext">
            <span class="border">
              Characters
            </span>
          </div>
        </div>
      </section>
  
      <div id='intros-mobile' id='intros-mobile'>
        <div class="chars-content-m">
          <h2 class="mobile-outline">YOU</h2>
          <img class="chars-pic-m" src="../../assets/images/foul_play_mc_snapshot.png" alt="Image of Main Character from Foul Play">
          <p>You're a hard-working and studious investigator. You've always played by the rules and upheld conventional
            standards. You're probably not gay, you've just always thought that girls are really gorgeous and so soft,
            feminine. It doesn't really matter what you fantasize about in your head, that's sooo not the real you...
            Right?</p>

        </div>
        <div class="chars-content-m">
            <h2 class="mobile-outline">WINTER</h2>
            <img class="chars-pic-m" src="../../assets/images/foul_play_winter_snapshot.png" alt="Image of Winter from Foul Play">
            <p>She’s emotionally detached and living her best life, speeding by herself in the HOV lane. Coming from
              nothing to a lucrative assassin salary, she has acquired an expensive taste: designer brand, customized
              Ninja H2R, contemporary penthouse in the heart of the city.</p>
        </div>

        <div class="chars-content-m">
            <h2 class="mobile-outline">CYNTHIA</h2>
            <img class="chars-pic-m" src="../../assets/images/foul_play_cynthia_snapshot.png" alt="Image of Cynthia from Foul Play">
            <p>The ice queen <del>alcoholic</del> workaholic that swats aside anyone who gets in her way or becomes a
                  burden on the job. She’s 6 feet of bottled fury ready to explode without notice. Give her some credit
                  though, she's trying to listen to her therapist's advice.</p>
        </div>
      </div>
      <!-- End mobile char -->
  
      <section class="char-intros" id="char-intros">
          <div class="row row-flex row-flex-wrap character-row ">
              <div class="col-sm-1">
                  <div class="mc-char-description">
                    <p>You're a hard-working and studious investigator. You've always played by the rules and upheld conventional
                      standards. You're probably not gay, you've just always thought that girls are really gorgeous and so soft,
                      feminine. It doesn't really matter what you fantasize about in your head, that's sooo not the real you...
                      Right?</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mc-column-image">
                      <h2 class="mc-filled-text">YOU</h2>
                      <h2 class="mc-outline-text">YOU</h2>
                      <img class="mc-image" src="../../assets/images/foul_play_mc_snapshot.png" alt="Image of Main Character from Foul Play">
                  </div>
                </div>
          </div>
          <div class="row row-flex row-flex-wrap character-row ">
              <div class="col-md-3">
                <div class="">
                    <h2 class="w-filled-text">WINTER</h2>
                    <h2 class="w-outline-text">WINTER</h2>
                    <img class="w-image" src="../../assets/images/foul_play_winter_snapshot.png" alt="Image of Winter from Foul Play">
                  </div>
              </div>
              <div class="col-sm-1">
                <div class="w-char-description">
                  <p>She’s emotionally detached and living her best life, speeding by herself in the HOV lane. Coming from
                    nothing to a lucrative assassin salary, she has acquired an expensive taste: designer brand, customized
                    Ninja H2R, contemporary penthouse in the heart of the city.</p>
                </div>
              </div>
          </div>
          <div class="row row-flex row-flex-wrap character-row ">
            <div class="col-sm-1">
              <div class="c-char-description">
                <p>The ice queen <del>alcoholic</del> workaholic that swats aside anyone who gets in her way or becomes a
                  burden on the job. She’s 6 feet of bottled fury ready to explode without notice. Give her some credit
                  though, she's trying to listen to her therapist's advice.</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mc-column-image">
                  <h2 class="c-filled-text">CYNTHIA</h2>
                  <h2 class="c-outline-text">CYNTHIA</h2>
                  <img class="c-image" src="../../assets/images/foul_play_cynthia_snapshot.png" alt="Image of Cynthia from Foul Play">
                </div>
            </div>
            
      </div>
      </section>

      <!-- <section class="page-section">
        <div class="socials">
          <iframe frameborder="0" src="https://itch.io/embed/717342?border_width=0&amp;bg_color=080808&amp;fg_color=ffffff&amp;link_color=9e5afa" width="550" height="165"><a href="https://banditvisualgames.itch.io/foul-play">Foul Play by Bandit Visual Games</a></iframe>
        </div>
      </section> -->
  
  
      <section class="page-section" id='contact'>
        <div class="cbanner" id="pimg"></div>
        <div class="cbanner-m" id="cbanner-m"></div>
  
        <div class="pimg3" id="pimg">
          <div class="ptext">
            <span class="border">
              Get In Touch
            </span>
          </div>
        </div>
  
        <div class="pimg3-m" id="pimg3-m">
          <div class="ptext-m ">
            Get In Touch
          </div>
        </div>



        <div class='row'>
          <div class="center">
            <div id="social">
              <ul>
                <li class="envelope">
                  <a href="mailto:banditvisualgames@gmail.com" target="_blank" alt="Select Button Send an Email to Bandit Visual Games">
                    <button class="border"><i class="fa fa-envelope"></i></button>
                  </a>
                </li>
  
                <li class="twitter">
                  <a href="https://twitter.com/BanditVisual" target="_blank" alt="Select Button Bandit Visual Games Twitter">
                    <button class="border"><i class="fa fa-twitter"></i></button>
                  </a>
                </li>

                <li class="instagram">
                  <a href="https://www.instagram.com/banditvisualgames/" target="_blank" alt="Select Button Bandit Visual Games Instagram">
                    <button class="border"><i class="fa fa-instagram"></i></button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  
  